export const FIREBASE_AUTH_ERRORS: Record<string, string> = {
  "auth/admin-restricted-operation": "Admin restricted operation",
  "auth/argument-error": "Argument error",
  "auth/app-not-authorized": "App not authorized",
  "auth/app-not-installed": "App not installed",
  "auth/captcha-check-failed": "Captcha check failed",
  "auth/code-expired": "Code expired",
  "auth/cordova-not-ready": "Cordova not ready",
  "auth/cors-unsupported": "Cors unsupported",
  "auth/credential-already-in-use": "Credential already in use",
  "auth/custom-token-mismatch": "Custom token mismatch",
  "auth/requires-recent-login": "Requires recent login",
  "auth/dependent-sdk-initialized-before-auth":
    "Dependent sdk initialized before auth",
  "auth/dynamic-link-not-activated": "Dynamic link not activated",
  "auth/email-change-needs-verification": "Email change needs verification",
  "auth/email-already-in-use": "Email already in use",
  "auth/emulator-config-failed": "Emulator config failed",
  "auth/expired-action-code": "Expired action code",
  "auth/cancelled-popup-request": "Cancelled popup request",
  "auth/internal-error": "Internal error",
  "auth/invalid-api-key": "Invalid api key",
  "auth/invalid-app-credential": "Invalid app credential",
  "auth/invalid-app-id": "Invalid app id",
  "auth/invalid-user-token": "Invalid user token",
  "auth/invalid-auth-event": "Invalid auth event",
  "auth/invalid-cert-hash": "Invalid cert hash",
  "auth/invalid-verification-code": "Invalid verification code",
  "auth/invalid-continue-uri": "Invalid continue uri",
  "auth/invalid-cordova-configuration": "Invalid cordova configuration",
  "auth/invalid-custom-token": "Invalid custom token",
  "auth/invalid-dynamic-link-domain": "Invalid dynamic link domain",
  "auth/invalid-email": "Invalid email",
  "auth/invalid-emulator-scheme": "Invalid emulator scheme",
  "auth/invalid-credential": "Invalid credential",
  "auth/invalid-message-payload": "Invalid message payload",
  "auth/invalid-multi-factor-session": "Invalid multi factor session",
  "auth/invalid-oauth-client-id": "Invalid oauth client id",
  "auth/invalid-oauth-provider": "Invalid oauth provider",
  "auth/invalid-action-code": "Invalid action code",
  "auth/unauthorized-domain": "Unauthorized domain",
  "auth/wrong-password": "Wrong password",
  "auth/invalid-persistence-type": "Invalid persistence type",
  "auth/invalid-phone-number": "Invalid phone number",
  "auth/invalid-provider-id": "Invalid provider id",
  "auth/invalid-recipient-email": "Invalid recipient email",
  "auth/invalid-sender": "Invalid sender",
  "auth/invalid-verification-id": "Invalid verification id",
  "auth/invalid-tenant-id": "Invalid tenant id",
  "auth/multi-factor-info-not-found": "Multi factor info not found",
  "auth/multi-factor-auth-required": "Multi factor auth required",
  "auth/missing-android-pkg-name": "Missing android pkg name",
  "auth/missing-app-credential": "Missing app credential",
  "auth/auth-domain-config-required": "Auth domain config required",
  "auth/missing-verification-code": "Missing verification code",
  "auth/missing-continue-uri": "Missing continue uri",
  "auth/missing-iframe-start": "Missing iframe start",
  "auth/missing-ios-bundle-id": "Missing ios bundle id",
  "auth/missing-or-invalid-nonce": "Missing or invalid nonce",
  "auth/missing-multi-factor-info": "Missing multi factor info",
  "auth/missing-multi-factor-session": "Missing multi factor session",
  "auth/missing-phone-number": "Missing phone number",
  "auth/missing-verification-id": "Missing verification id",
  "auth/app-deleted": "App deleted",
  "auth/account-exists-with-different-credential":
    "Account exists with different credential",
  "auth/network-request-failed": "Network request failed",
  "auth/null-user": "Null user",
  "auth/no-auth-event": "No auth event",
  "auth/no-such-provider": "No such provider",
  "auth/operation-not-allowed": "Operation not allowed",
  "auth/operation-not-supported-in-this-environment":
    "Operation not supported in this environment",
  "auth/popup-blocked": "Popup blocked",
  "auth/popup-closed-by-user": "Popup closed by user",
  "auth/provider-already-linked": "Provider already linked",
  "auth/quota-exceeded": "Quota exceeded",
  "auth/redirect-cancelled-by-user": "Redirect cancelled by user",
  "auth/redirect-operation-pending": "Redirect operation pending",
  "auth/rejected-credential": "Rejected credential",
  "auth/second-factor-already-in-use": "Second factor already in use",
  "auth/maximum-second-factor-count-exceeded":
    "Maximum second factor count exceeded",
  "auth/tenant-id-mismatch": "Tenant id mismatch",
  "auth/timeout": "Timeout",
  "auth/user-token-expired": "User token expired",
  "auth/too-many-requests": "Too many requests",
  "auth/unauthorized-continue-uri": "Unauthorized continue uri",
  "auth/unsupported-first-factor": "Unsupported first factor",
  "auth/unsupported-persistence-type": "Unsupported persistence type",
  "auth/unsupported-tenant-operation": "Unsupported tenant operation",
  "auth/unverified-email": "Unverified email",
  "auth/user-cancelled": "User cancelled",
  "auth/user-not-found": "User not found",
  "auth/user-disabled": "User disabled",
  "auth/user-mismatch": "User mismatch",
  "auth/user-signed-out": "User signed out",
  "auth/weak-password": "Weak password",
  "auth/web-storage-unsupported": "Web storage unsupported",
  "auth/already-initialized": "Already initialized",
};
